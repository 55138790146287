@import "../../../../styles/reset";

.preview-type-container {
  @include control-container;

  .preview-type {
    display: flex;
    flex-direction: row;
    border: $size-1 solid $component-border-color;
    border-radius: $size-4;
    padding: $size-14 $size-16;
    gap: $size-20;

    .preview-type-radio {
      display: none;
    }

    &:not(:last-of-type) {
      margin-bottom: $size-8;
    }

    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: $size-5;

      .left-top {
        display: flex;
        flex-direction: row;
      }
    }

    &.list {
      .right {
        justify-content: center;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: $size-5;
      margin-bottom: $size-6;

      .right-top {
        display: flex;
        flex-direction: row;
        gap: $size-7;

        .btn-gallery-size {
          background-color: transparent;
          color: #ffffff;
          white-space: nowrap;
          font-size: $size-9;
          line-height: $size-12;
          padding: $size-6 $size-10;
          border-radius: $size-5;

          &.active {
            background-color: $component-bg-active;
          }

          &:not(.disabled):not(.active):hover {
            background-color: $component-bg-hover;
          }
        }
      }

      .right-bottom {
        .image-size {
          .slider-container {
            margin-top: $size-20;
          }
        }

        .slider {
          width: $size-88;
        }

        .grid-size {
          .grid-size-desc {
            font-size: $size-10;
            text-align: left;
            margin: 0 0 $size-6;

            span {
              background-color: #6a6a6a;
              font-size: $size-10;
              padding: 0 $size-6;
              border-radius: $size-1;
            }
          }
        }

        .notice {
          background-color: $notice-color;
          border-radius: $size-1;
          font-size: $size-8;
          margin-top: $size-8;
          padding: 0 $size-3;
        }
      }

      .description {
        font-size: $size-10;
        text-align: left;
        font-style: italic;
      }
    }

    .preview-type--label {
      margin-left: $size-3;
    }
  }
}
