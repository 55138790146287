@import "../../../styles/variables.scss";

/******************************************************/
.image-overlay {
  z-index: $z-index-header;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  color: $component-color;
}

$overlay-left-panel-width: 360px; // fixed width to avoid scrollbars, because image is resized in overlay as integer
$overlay-right-panel-width: 380px; // fixed width to avoid scrollbars, because image is resized in overlay as integer

$overlay-left-right-panel-padding: $size-16;
$overlay-left-right-content-padding: $size-4;

.image-overlay--left,
.image-overlay--right {
  z-index: $z-index-overlay-controls;
  background-color: $overlay-left-right-panel-bg-color;
  flex: none;
}

.image-overlay--left {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: $overlay-left-panel-width;
  /*flex: 0 0 $overlay-left-panel-width;*/
  padding-bottom: $size-70;
}

.image-overlay--right {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: $overlay-right-panel-width;
  /*flex: 0 0 $overlay-right-panel-width;*/
}

.image-overlay--middle {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #000;

  .show-interface-btn {
    z-index: $z-index-overlay-controls;
    position: absolute;
    bottom: $size-26;
    left: $size-26;
    border: $size-1 solid #b8b8b8;
    border-radius: $size-4;
    background-color: #4b4c4e;
    color: #fff;
    font-size: $size-8;
    font-family: $font-semibold;
    width: $size-71;
    height: $size-22;
  }
}

.image-overlay--image__wrap {
  position: absolute;
  overflow: auto;

  svg,
  canvas {
    position: absolute;
  }
}

.image-overlay--left__top,
.image-overlay--right__top {
  flex: 1 1 auto;
  overflow: auto;
}

.image-overlay--left__bottom {
  z-index: $z-index-overlay-controls;
  position: absolute;
  bottom: $size-16;
  left: $size-16;
  width: $size-328;
  height: $size-42;
  background-color: $overlay-left-right-panel-bg-color;
  border: $size-1 solid #707070;
  border-radius: $size-3;
}

.image-overlay--left__buttons {
  display: flex;
  justify-content: space-between;
  padding: $size-7 $size-10;

  .image-overlay--left__button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $size-4;
    height: $size-27;
    background-color: transparent;
    border: none;
    color: #ffffff;
    font-size: $size-12;
    padding: $size-6 $size-8;
    border-radius: $size-3;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: $component-bg-active;
    }

    &.disabled {
      color: $header-component-color-disabled;

      svg {
        path {
          fill: $header-component-color-disabled;
        }
      }
    }

    &:not(.disabled):not(.active):hover {
      background-color: $overlay-controls-bg-hover;
    }
  }
}

.image-overlay__title {
  display: flex;
  position: relative;
  font-family: $font-semibold;
  font-size: $size-12;
  height: $size-40;
  border-top: $size-1 solid $component-border-color;
  margin: 0 $overlay-left-right-panel-padding;
  padding: 0 0;
  cursor: pointer;

  &.expanded {
    height: $size-34;
  }

  .image-overlay--history-toggle {
    position: absolute;
    right: 0;
    cursor: pointer;
    line-height: $size-20;
    padding: 0 $size-6;
    border-radius: $size-3;

    &:hover {
      background-color: $component-bg-hover;
    }
  }

  .image-overlay__title-text {
    position: relative;
    margin-top: $size-10;
    padding-left: $overlay-left-right-content-padding;
    height: $size-20;
    width: 100%;
    line-height: $size-20;
    border-radius: $size-3;
    font-family: $font-semibold;
    font-size: $size-13;

    &:not(.disabled):not(.active):hover {
      background-color: $component-bg-hover;
    }

    svg {
      position: absolute;
      top: $size-6;
      right: $size-6;

      &.title-icon {
        transition: transform 0.3s;
      }

      &.expanded {
        transform: rotate(90deg);
      }

      &.notice {
        path {
          fill: $notice-color;
        }
      }
    }
  }

  &:first-of-type {
    border-top: $size-1 solid transparent;
  }
}

.image-overlay--left__content {
  display: flex;
  flex-direction: column;
  gap: $size-10;
  padding: $size-6 calc($overlay-left-right-panel-padding + $overlay-left-right-content-padding) $size-10
    calc($overlay-left-right-panel-padding + $overlay-left-right-content-padding);

  .info {
    font-size: $size-12;

    &.info-error {
      background-color: #7a3941;
      border-radius: $size-2;
      width: fit-content;
      padding-left: $size-3;
      padding-right: $size-3;
    }

    span {
      font-size: inherit;
      border: none;
      height: $size-16;
      line-height: $size-15;
    }
  }

  .slider-container {
    .slider {
      width: $size-154;
    }
  }

  .path-list {
    display: flex;
    flex-direction: column;
    gap: $size-5;

    .checkbox-container {
      width: 100%;

      label:last-of-type {
        width: 100%;
      }
    }

    .path-item {
      display: flex;
      flex-direction: row;
      gap: $size-5;
      align-items: center;
      font-size: $size-12;
      padding: 0 $size-4 0 $size-4;
      border-radius: $size-2;

      &:hover {
        background-color: $component-bg-hover;
      }

      .path-color {
        position: relative;
        border-radius: $size-1;
        width: $size-10;
        height: $size-10;
        min-width: $size-10;
        min-height: $size-10;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .path-outside-image {
      position: relative;
      right: -$size-4;
      border-radius: $size-2;
      width: $size-20;
      height: $size-20;

      &:hover {
        cursor: pointer;
      }

      svg {
        position: absolute;
        top: $size-2;
        left: $size-1;
        width: $size-14;
        height: $size-14;
      }

      &.active {
        background-color: $component-bg-active;

        svg {
          path, rect {
            stroke: #FFA117;
          }
        }
      }
    }
  }

  .layers-container {
    .radio-container {
      gap: $size-2;
      padding: 0 $size-4 0 $size-4;
      border-radius: $size-2;

      &:hover {
        background-color: $component-bg-hover;
      }

      label:last-of-type {
        width: 100%;
      }

      .layer-item {
        display: flex;
        align-items: center;
        gap: $size-4;

        svg {
          min-width: $size-14;
        }
      }
    }

    .layer-error-container {
      margin-top: $size-6;
    }
  }

  .alpha-channel-list {
    display: flex;
    flex-direction: column;
    gap: $size-5;

    .alpha-channel-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $size-5;
    }
  }

  .grid-list-container {
    display: flex;
    flex-direction: column;
    gap: $size-5;

    .checkbox-container {
      width: 100%;

      label:last-of-type {
        width: 100%;
      }
    }

    .grid-list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $size-5;
      padding: 0 $size-4 0 $size-4;
      border-radius: $size-2;

      &:hover {
        background-color: $component-bg-hover;
      }

      .grid-item-color {
        position: relative;
        border-radius: $size-1;
        width: $size-10;
        height: $size-10;
        min-width: $size-10;
        min-height: $size-10;

        &.no-color {
          background-color: transparent;
        }
      }

      .grid-item-template-name {
        color: #6b6b6b;
      }
    }
  }
}

.image-overlay--right__content,
.image-overlay--left__content {
  max-height: $size-270;
  margin-right: $size-6;
}

.image-overlay--right__content {
  max-height: calc((100vh - $size-200) / 3);
}

.image-overlay--img,
.image-overlay--guide,
.image-overlay--grid,
.image-overlay--underlay {
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
}

.image-overlay--img {
  /*cursor: move;*/
}

/******************************************************/
.image-overlay--navigation {
  display: flex;
  gap: $size-18;
  padding: $size-10;
}

$navigation-control-height: $size-34;

.image-overlay--navigation__wrap {
  display: flex;
  gap: $size-4;
  border: $size-1 solid $component-border-color;
  border-radius: $size-5;
  height: $navigation-control-height;
  padding: $size-2 $size-4;
}

/******************************************************/
.image-overlay__control {
  cursor: pointer;
  position: relative;
  top: $size-2;
  width: calc($navigation-control-height - $size-4);
  height: calc($navigation-control-height - $size-9);
  line-height: calc($navigation-control-height - $size-9);
  border-radius: $size-5;
  text-align: center;

  &.active {
    background-color: $overlay-controls-bg-active;
  }

  &.disabled {
    path {
      fill: $component-color-disabled;
    }
  }

  &:not(.disabled):not(.active):hover {
    background-color: $overlay-controls-bg-hover;
  }

  svg {
    position: absolute;
    top: $size-1;
    left: $size-1;
  }
}
/******************************************************/
.image-overlay__compare {
  svg {
    top: $size-2;
    left: $size-3;
  }
}

/******************************************************/
.image-overlay__zoom {
  font-size: $size-10;
  font-family: $font-semibold;
  color: #6B6B6B;
}

.image-overlay__zoom svg {
  top: $size-5;
  left: $size-6;
}

.image-overlay__drag svg {
  top: $size-1;
  left: $size-3;
}

/******************************************************/
.image-overlay__prev svg {
  left: $size-2;
}

.image-overlay__next svg {
  left: $size-1;
}

.image-overlay__close {
  z-index: $z-index-overlay-controls;
  position: absolute;
  top: $size-8;
  right: $size-8;
  cursor: pointer;
}

.image-overlay--images-counter {
  position: relative;
  top: $size-16;
  margin-left: auto;

  min-width: $size-50;
  text-align: right;

  span {
    font-family: $font-semibold;
    font-size: $size-9;
  }

  .image-overlay--images-counter__separator {
    padding: 0 $size-3;
  }
}

.magnifier-overlay {
  z-index: $z-index-draggable-select;
  position: absolute;
  border-radius: 50%;
  width: $size-300;
  height: $size-300;
  overflow: hidden;
  pointer-events: none;
  display: none;
  box-shadow: 0 0 $size-8 rgba(0, 0, 0, 0.25);

  img,
  svg {
    position: absolute;
  }
}

.image-overlay--drawing {
  z-index: $z-index-drawing;
}