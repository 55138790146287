@import '../../styles/reset';

/******************************************************/
.gallery-content {
  position: absolute;
  top: $header-height;
  width: 100%;
  height: calc(100vh - $header-height);
  overscroll-behavior: none;

  .scroll-to-top {
    position: fixed;
    z-index: $z-index-preview;
    right: $size-22;
    bottom: $size-16;
    border-radius: $size-6;
    background-color: rgba(51, 51, 51, 0.9);
    width: $size-46;
    height: $size-46;

    svg {
      position: absolute;
      top: $size-10;
      left: $size-11;
    }
  }
}

.images--wrap {
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  min-width: $gallery-min-width;
  margin: 0 auto;
  padding: $size-15 $size-15 $size-70 $size-15;
}

.drag-select {
  z-index: $z-index-draggable-select;
  border: $size-2 dashed rgba(20, 137, 189, 0.5);
}