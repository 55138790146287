@import 'variables';

/******************************************************/
* {
  @include font-mixing;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  /*overscroll-behavior: none;*/
}

html,
body {
  margin: 0;
  padding: 0;
  color: $light-color;
  background-color: $bg-color;
  @include font-mixing;
}

::-moz-selection {
  color: $text-selection-color;
  background: $text-selection-bg;
}

::selection {
  color: $text-selection-color;
  background: $text-selection-bg;
}

i {
  font-family: $font-italic;
}

b, strong, span.bold {
  font-family: $font-semibold;
}

button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  @include font-mixing;
}

button:hover {
  transition: background-color .5s;
}

.bg-color-transition {
  transition: background-color .5s;
}

table {
  border-spacing: initial;
}

p,
span {
  margin: 0;
  line-height: 1.4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.4;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul,
ol {
  line-height: 1.75;
}

svg,
img {
  outline: none;
}

label {
  cursor: pointer;
}

a {
  color: $dark-color;
  border-bottom: $size-1 solid $dark-color;
  text-decoration: none;
}

a:hover {
  color: $dark-color;
}

.a-no-border {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

/******************************************************/
.width-1 {
  width: $size-1;
}

.width-2 {
  width: $size-2;
}

.width-3 {
  width: $size-3;
}

.width-4 {
  width: $size-4;
}

.width-5 {
  width: $size-5;
}

.width-6 {
  width: $size-6;
}

.width-7 {
  width: $size-7;
}

.width-8 {
  width: $size-8;
}

.width-9 {
  width: $size-9;
}

.width-10 {
  width: $size-10;
}

.width-11 {
  width: $size-11;
}

.width-12 {
  width: $size-12;
}

.width-13 {
  width: $size-13;
}

.width-14 {
  width: $size-14;
}

.width-15 {
  width: $size-15;
}

.width-16 {
  width: $size-16;
}

.width-17 {
  width: $size-17;
}

.width-18 {
  width: $size-18;
}

.width-19 {
  width: $size-19;
}

.width-20 {
  width: $size-20;
}

.width-21 {
  width: $size-21;
}

.width-22 {
  width: $size-22;
}

.width-23 {
  width: $size-23;
}

.width-24 {
  width: $size-24;
}

.width-25 {
  width: $size-25;
}

.width-26 {
  width: $size-26;
}

.width-27 {
  width: $size-27;
}

.width-28 {
  width: $size-28;
}

.width-29 {
  width: $size-29;
}

.width-30 {
  width: $size-30;
}

.width-31 {
  width: $size-31;
}

.width-34 {
  width: $size-34;
}

.width-40 {
  width: $size-40;
}

.width-42 {
  width: $size-42;
}

.width-43 {
  width: $size-43;
}

.width-58 {
  width: $size-58;
}

.width-60 {
  width: $size-60;
}

.width-71 {
  width: $size-71;
}

.width-100 {
  width: $size-100;
}

.width-132 {
  width: $size-132;
}

.width-314 {
  width: $size-314;
}

.width-100vw {
  width: 100vw;
}

/******************************************************/
.height-1 {
  height: $size-1;
}

.height-2 {
  height: $size-2;
}

.height-3 {
  height: $size-3;
}

.height-4 {
  height: $size-4;
}

.height-5 {
  height: $size-5;
}

.height-6 {
  height: $size-6;
}

.height-7 {
  height: $size-7;
}

.height-8 {
  height: $size-8;
}

.height-9 {
  height: $size-9;
}

.height-10 {
  height: $size-10;
}

.height-11 {
  height: $size-11;
}

.height-12 {
  height: $size-12;
}

.height-13 {
  height: $size-13;
}

.height-14 {
  height: $size-14;
}

.height-15 {
  height: $size-15;
}

.height-16 {
  height: $size-16;
}

.height-17 {
  height: $size-17;
}

.height-18 {
  height: $size-18;
}

.height-19 {
  height: $size-19;
}

.height-20 {
  height: $size-20;
}

.height-21 {
  height: $size-21;
}

.height-22 {
  height: $size-22;
}

.height-23 {
  height: $size-23;
}

.height-24 {
  height: $size-24;
}

.height-25 {
  height: $size-25;
}

.height-26 {
  height: $size-26;
}

.height-27 {
  height: $size-27;
}

.height-28 {
  height: $size-28;
}

.height-29 {
  height: $size-29;
}

.height-30 {
  height: $size-30;
}

.height-40 {
  height: $size-40;
}

.height-42 {
  height: $size-42;
}

.height-51 {
  height: $size-51;
}

.height-54 {
  height: $size-54;
}

.height-59 {
  height: $size-59;
}

.height-66 {
  height: $size-66;
}

.height-68 {
  height: $size-68;
}

.height-72 {
  height: $size-72;
}

.height-132 {
  width: $size-132;
}

.height-150 {
  height: $size-150;
}

/******************************************************/
.m-top-auto {
  margin-top: auto;
}

.m-top-4 {
  margin-top: $size-4;
}

.m-top-5 {
  margin-top: $size-5;
}

.m-top-6 {
  margin-top: $size-6;
}

.m-top-7 {
  margin-top: $size-8;
}

.m-top-8 {
  margin-top: $size-8;
}

.m-top-9 {
  margin-top: $size-9;
}

.m-top-10 {
  margin-top: $size-10;
}

.m-top-11 {
  margin-top: $size-11;
}

.m-top-12 {
  margin-top: $size-12;
}

.m-top-13 {
  margin-top: $size-13;
}

.m-top-14 {
  margin-top: $size-14;
}

.m-top-15 {
  margin-top: $size-15;
}

.m-top-16 {
  margin-top: $size-16;
}

.m-top-17 {
  margin-top: $size-17;
}

.m-top-18 {
  margin-top: $size-18;
}

.m-top-19 {
  margin-top: $size-19;
}

.m-top-20,
.m-top-20-mobile {
  margin-top: $size-20;
}

.m-top-21 {
  margin-top: $size-21;
}

.m-top-22 {
  margin-top: $size-22;
}

.m-top-23 {
  margin-top: $size-23;
}

.m-top-24 {
  margin-top: $size-24;
}

.m-top-25 {
  margin-top: $size-25;
}

.m-top-26 {
  margin-top: $size-26;
}

.m-top-27 {
  margin-top: $size-27;
}

.m-top-28 {
  margin-top: $size-28;
}

.m-top-29 {
  margin-top: $size-29;
}

.m-top-30 {
  margin-top: $size-30;
}

.m-top-31 {
  margin-top: $size-31;
}

.m-top-32 {
  margin-top: $size-32;
}

.m-top-35 {
  margin-top: $size-35;
}

.m-top-37 {
  margin-top: $size-37;
}

.m-top-38 {
  margin-top: $size-38;
}

.m-top-40 {
  margin-top: $size-40;
}

.m-top-50 {
  margin-top: $size-50;
}

.m-top-70 {
  margin-top: $size-70;
}

/******************************************************/
.m-bottom-8 {
  margin-bottom: $size-8;
}

.m-bottom-10 {
  margin-bottom: $size-10;
}

.m-bottom-20 {
  margin-bottom: $size-20;
}

.m-bottom-25 {
  margin-bottom: $size-25;
}

.m-bottom-26 {
  margin-bottom: $size-26;
}

.m-bottom-32 {
  margin-bottom: $size-32;
}

.m-bottom-38 {
  margin-bottom: $size-38;
}

.m-bottom-39 {
  margin-bottom: $size-39;
}

.m-bottom-40 {
  margin-bottom: $size-40;
}

.m-bottom-42 {
  margin-bottom: $size-42;
}

/******************************************************/
.top--1 {
  top: (-$size-1);
}

.top--2 {
  top: (-$size-2);
}

.top--3 {
  top: (-$size-3);
}

.top--4 {
  top: (-$size-4);
}

.top--5 {
  top: (-$size-5);
}

.top--6 {
  top: (-$size-6);
}

.top--7 {
  top: (-$size-7);
}

.top--8 {
  top: (-$size-8);
}

.top--9 {
  top: (-$size-9);
}

.top--10 {
  top: (-$size-10);
}

.top-0 {
  top: 0;
}

.top-1 {
  top: $size-1;
}

.top-2 {
  top: $size-2;
}

.top-3 {
  top: $size-3;
}

.top-4 {
  top: $size-4;
}

.top-5 {
  top: $size-5;
}

.top-6 {
  top: $size-6;
}

.top-7 {
  top: $size-7;
}

.top-8 {
  top: $size-8;
}

.top-9 {
  top: $size-9;
}

.top-10 {
  top: $size-10;
}

.top-11 {
  top: $size-11;
}

.top-12 {
  top: $size-12;
}

.top-13 {
  top: $size-13;
}

.top-14 {
  top: $size-14;
}

.top-15 {
  top: $size-15;
}

.top-16 {
  top: $size-16;
}

.top-17 {
  top: $size-17;
}

.top-18 {
  top: $size-18;
}

.top-19 {
  top: $size-19;
}

.top-20 {
  top: $size-20;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: $size-1;
}

.right-2 {
  right: $size-2;
}

.right-3 {
  right: $size-3;
}

.right-4 {
  right: $size-4;
}

.right-5 {
  right: $size-5;
}

.right-6 {
  right: $size-6;
}

.right-7 {
  right: $size-7;
}

.right-8 {
  right: $size-8;
}

.right-9 {
  right: $size-9;
}

.right-10 {
  right: $size-10;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: $size-1;
}

.left-2 {
  left: $size-2;
}

.left-3 {
  left: $size-3;
}

.left-4 {
  left: $size-4;
}

.left-5 {
  left: $size-5;
}

.left-6 {
  left: $size-6;
}

.left-7 {
  left: $size-7;
}

.left-8 {
  left: $size-8;
}

.left-9 {
  left: $size-9;
}

.left-10 {
  left: $size-10;
}

/******************************************************/
.disabled:hover {
  cursor: default; // element should not be clickable
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.column-half {
  width: 100%;
}

.screen-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.screen-center-top {
  position: absolute;
  left: 50%;
  top: $center-top;
  transform: translate(-50%, (-$center-top));
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.fit-content {
  width: fit-content;
}

.column-gap-16 {
  column-gap: $size-16;
}

.error {
  color: $input-error-color;
}

.word-break {
  word-break: $break-word;
}

/******************************************************/
$scrollbar-width: $size-8;
$scrollbar-small-width: $size-5;
$scrollbar-big-width: $size-13;

.scrollbar-overflow {
  overflow-x: auto;
  overflow-y: auto;

  // This next 2 lines are for Firefox
  @supports (-moz-appearance: none) {
    /*scrollbar-color: $scrollbar-thumb-bg #FFFFFF;
    scrollbar-width: $scrollbar-width;*/
  }
}

.scrollbar-overflow {
  overflow-x: $overflow-overlay;
  overflow-y: $overflow-overlay;
}

.scrollbar-overflow::-webkit-scrollbar-track {
  margin-top: 0;
}

.scrollbar-overflow__small::-webkit-scrollbar-track {
  margin-top: $size-5;
}

.scrollbar-overflow::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

.scrollbar-overflow__small::-webkit-scrollbar {
  width: $scrollbar-small-width;
  height: $scrollbar-small-width;
}

.scrollbar-overflow__zero::-webkit-scrollbar { /* can be used in fullscreen view to completely hide scrollbars */
  width: 0;
  height: 0;
}

.scrollbar-overflow__big::-webkit-scrollbar {
  width: $scrollbar-big-width;
  height: $scrollbar-big-width;
}

.scrollbar-overflow::-webkit-scrollbar-thumb {
  border-radius: calc($scrollbar-width / 2);
  background-color: $scrollbar-thumb-bg;
}

.scrollbar-overflow__light::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-bg-light;
}

.scrollbar-overflow::-webkit-scrollbar-corner {
  background-color: transparent;
}

.overflow-visible {
  overflow: visible;
}

/******************************************************/
$skeleton-speed: 2.25s;

.skeleton {
  background: linear-gradient(90deg, #d7d7d7, #f0f0f0, #d7d7d7, #f0f0f0);
  background-size: 400% 400%;
  animation: skeleton-gradient $skeleton-speed ease infinite;
  min-height: $size-10;
}

@-webkit-keyframes skeleton-gradient {
  0% {
    background-position: 100% 50%
  }

  50% {
    background-position: 0 50%
  }

  100% {
    background-position: 100% 50%
  }
}

@-moz-keyframes skeleton-gradient {
  0% {
    background-position: 100% 50%
  }

  50% {
    background-position: 0 50%
  }

  100% {
    background-position: 100% 50%
  }
}

@keyframes skeleton-gradient {
  0% {
    background-position: 100% 50%
  }

  50% {
    background-position: 0 50%
  }

  100% {
    background-position: 100% 50%
  }
}

/******************************************************/
/* Coloris: color picker styles. Docs: https://coloris.js.org */
$coloris-bg: #333333;

.clr-picker {
  padding-bottom: $size-20;
  background-color: $coloris-bg;
  border-radius: $size-5;

  #clr-color-value {
    background-color: #333333;
    border: 0.5px solid #707070;
    border-radius: $size-3;
    width: $size-100;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  /* Coloris: hide some elements */
  #clr-color-value, #clr-color-preview {
    display: none;
  }
}

.coloris-input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

/******************************************************/