@import '../../../styles/reset';

.image-preview--comments__wrap {
  border-radius: $size-3;
  margin: 0 $size-7;
  background-color: #333;
  color: #fff;

  .image-preview--comment__wrap {
    display: flex;
    position: relative;
    padding: $size-4 $size-10;
    margin: 0;

    &.image-preview--comment__wrap--deleted {
      color: #67686A;

      svg {
        path {
          stroke: $component-color-disabled;
        }

        rect {
          stroke: $component-color-disabled;
        }
      }

      .image-preview--comment__icon--client {
        background-color: #4B4C4E;
        color: #6C6C6C;
      }
    }
  }

  .image-preview--comment__wrap:hover {
    background-color: #000;

    .image-preview--comment__delete {
      display: flex;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .image-preview--comments__separator {
    height: $size-1;
    width: calc(100% - $size-22);
    padding: 0;
    margin: 0 auto;
    background-color: #4B4C4E;
  }

  .image-preview--comment__icon {
    position: relative;
    width: $comment-icon-client-size;
    height: $comment-icon-client-size;
    min-width: $comment-icon-client-size;
    min-height: $comment-icon-client-size;
    margin-right: $size-6;

    &.image-preview--comment__icon--rejected {
      top: $size-2;
      left: $size-1;
      margin-right: $size-9;
    }
  }

  .image-preview--comment__icon--client {
    top: $size-2;
    font-family: $font-semibold;
    font-size: $size-8;
    line-height: $comment-icon-client-size;
    border-radius: $size-2;
    text-align: center;
    margin-right: $size-8;
    background-color: $client-comment-bg;
  }

  .image-preview__icon--client__approved {
    background-color: $client-comment-approved-bg;
  }

  .image-preview--comment__delete {
    background-color: #fff;
    border-radius: $size-2;
    position: absolute;
    top: $size-13;
    transform: translateY(-50%);
    right: $size-6;
    display: none;
    align-items: center;
    justify-content: center;
    padding: $size-4;

    svg {
      width: $size-10;
      height: $size-10;

      path {
        fill: #333;
      }
    }
  }

  &.image-preview--comments__wrap--input {
    background-color: #555;

    .image-preview--comment__wrap {
      &:hover {
        background-color: #555;
      }
    }
  }
}
