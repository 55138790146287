@import '../../../styles/reset';

$unread-width: $size-18;

.header--filters__chat {
  position: relative;

  .header--filters__chat--counter {
    position: absolute;
    z-index: 1;
    top: $size-1;
    right: $size-1;
    font-size: $size-10;

    min-width: $unread-width;
    min-height: $unread-width;
    line-height: calc($unread-width - $size-2);
    border-radius: calc($unread-width / 2);

    background-color: $gallery-error-color;
    color: #fff;
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }

  .header--filters__chat--counter.blinking {
    animation: blink 2s infinite;
  }

}
