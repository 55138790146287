@import '../../styles/reset';

$gallery-list-width: $size-1380;

.gallery-content--list {
  background-color: #000;

  .gallery-content__header {
    display: flex;
    width: $gallery-list-width;
    margin: $size-44 auto $size-18 auto;
    color: #fff;

    .gallery-content__header__title {
      width: $size-420;
    }

    .gallery-content__header__actions {
      margin-left: auto;
      text-align: right;
    }

    .export-csv-btn {
      height: $size-36;
      line-height: $size-36;
      background-color: #333;
      border-radius: $size-4;
      padding: 0 $size-16;
      font-size: $size-14;
      font-family: $font-semibold;
      color: #fff;

      &:hover {
        background-color: $component-bg-hover;
      }

      svg {
        position: relative;
        top: $size-6;
        left: -$size-6;
      }
    }
  }

  .image-row {
    display: flex;
    align-items: center;
    width: $gallery-list-width;
    height: $size-60;
    line-height: $size-60;
    margin: 0 auto;
    color: #fff;
    border-bottom: 1px solid #fff;

    &.image-row-light {
      background-color: #333;
    }
  }

  $width-without-comment: $size-20;

  .col-id {
    width: calc($size-80 + $width-without-comment);
  }

  .col-filename {
    width: calc($size-400 + $width-without-comment);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .col-width {
    width: calc($size-100 + $width-without-comment);
  }

  .col-height {
    width: calc($size-100 + $width-without-comment);
  }

  .col-resolution {
    width: calc($size-120 + $width-without-comment);
  }

  .col-size {
    width: calc($size-120 + $width-without-comment);
  }

  .col-color-space {
    width: calc($size-160 + $width-without-comment);
  }

  .col-background {
    width: calc($size-100 + $width-without-comment);
  }

  .col-comments {
    /*width: $size-200;*/
    width: 0;
  }

  .col--header {
    padding: 0 $size-10;
    font-size: $size-13;
    font-family: $font-semibold;
  }

  .col-row {
    flex-shrink: 0;
    padding: 0 $size-10;
    font-size: $size-14;

    &.skeleton {
      width: 90%;
    }
  }
}
