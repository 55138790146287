@import '../../styles/reset';

/******************************************************/
.header {
  z-index: $z-index-header;
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: $header-height;
  background-color: $header-bg;

  /******************************************************/
  .header--middle__wrap--left, .header--middle__wrap--right {
    display: flex;
    gap: $size-10;
  }

  .header--middle__wrap--left {
    padding-left: $size-20;
  }

  .header--middle__wrap--right {
    padding-right: $size-15;
  }

  .header--wraps {
    display: flex;
    margin-top: calc(($header-height - $header-controls-height) / 2);
  }

  .header--counters__wrap,
  .header--image-size__wrap,
  .header--avatars__wrap--wrap,
  .header--filters__search--wrap,
  .header--filters__chat,
  .header--filters__combine,
  .header--filters__save,
  .header--back__wrap,
  .header--image-info__wrap,
  .header--send-redo__wrap {
    display: none;
  }

  @media (min-width: 680px) {
    .header--send-redo__wrap {
      display: initial;
    }
  }

  @media (min-width: 748px) {
    .header--back__wrap,
    .header--image-info__wrap {
      display: initial;
    }
  }

  @media (min-width: 824px) {
    .header--filters__combine {
      display: initial;
    }
  }

  @media (min-width: 896px) {
    .header--filters__chat {
      display: initial;
    }
  }

  @media (min-width: 966px) {
    .header--filters__save {
      display: initial;
    }
  }

  @media (min-width: 1112px) {
    .header--counters__wrap {
      display: flex;
    }
  }

  @media (min-width: 1280px) {
    .header--filters__search--wrap {
      display: flex;
      flex-direction: column;
    }
  }

  @media (min-width: 1360px) {
    .header--avatars__wrap--wrap {
      display: block;
    }
  }

  @media (min-width: 1500px) {
    .header--image-size__wrap {
      display: flex;
    }
  }

  .header--image-size__wrap {
    margin-left: $size-12;
  }

  .header--counters__wrap {
    margin-top: $size-7;
  }

  .header--placeholder__wrap {
    width: $size-38;
    height: $size-34;
  }

  .header--back__wrap, .header--image-info__wrap, .header--send-redo__wrap {
    cursor: pointer;
    width: $size-38;
    height: $size-34;
    margin-top: $size-11;
    border-radius: $size-5;
    padding: $size-8 0 0 0;
    text-align: center;

    &:not(.disabled):not(.active):hover {
      background-color: $component-bg-hover;
    }

    svg {
      position: relative;
    }
  }

  .header--image-info__wrap--active {
    background-color: $component-bg-active;
  }

  .header--send-redo__wrap {
    &.disabled {
      svg {
        path {
          fill: $component-color-disabled;
        }
      }
    }
  }

  .header--back__wrap {
    svg {
      top: -$size-8;
    }
  }

  .slider-container {
    .slider {
      width: $size-88;
    }
  }

  .tooltip {
    display: flex;
  }
}
